import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AccommodationSkelton from '../../AccommodationPage/AccommodationSkelton';

// Function to conditionally load the image URL based on environment
function getPlacesImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION;
  }
}

function getPlacesapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_API_URL_PLACES_PRODUCTION;
  }
}

function PlacesList() {
  const imageUrl = getPlacesImageUrl();
  const placesApiUrl = getPlacesapiUrl();

  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayedPlaces, setDisplayedPlaces] = useState([]);
  const [filter, setFilter] = useState('default'); // Added state for filtering

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(placesApiUrl);
        setTimeout(() => {
          setPlaces(response.data);
          setDisplayedPlaces(response.data.slice(0, 6));
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchPlaces();
  }, []);

  // Filter logic based on user selection
  useEffect(() => {
    if (filter === 'default') {
      setDisplayedPlaces(places.slice(0, 6)); // Default order
    } else if (filter === 'top rated') {
      const filteredPlaces = [...places].sort((a, b) => b.rating - a.rating); // Assuming `rating` exists
      setDisplayedPlaces(filteredPlaces.slice(0, 6));
    } else if (filter === 'popular') {
      const filteredPlaces = [...places].sort((a, b) => b.popularity - a.popularity); 
      // Assuming `popularity` exists
      setDisplayedPlaces(filteredPlaces.slice(0, 6));
    } else if (filter === 'recommended') {
      const filteredPlaces = places.filter(place => place.isRecommended); 
      // Assuming `isRecommended` is a boolean
      setDisplayedPlaces(filteredPlaces.slice(0, 6));
    }
  }, [filter, places]);

  const handleLoadMore = () => {
    if (displayedPlaces.length < places.length) {
      const newDisplayed = displayedPlaces.concat(
        places.slice(displayedPlaces.length, displayedPlaces.length + 3)
      );
      setDisplayedPlaces(newDisplayed);
    }
  };

  return (
    <section className="products-section pt-100 pb-90">
      <div className="container">
        <div className="page-item-filter mb-40 wow fadeInUp">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <div className="show-text mb-20">
                <h3>Best Places To Visit In Barot Valley</h3>
              </div>
            </div>
            
          </div>
        </div>

        <div className="row">
          {isLoading ? (
            <AccommodationSkelton />
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : (
            displayedPlaces.map((place, index) => (
              <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                <div className="single-product-item mb-50 wow fadeInUp">
                  <div className="img-holder">
                    <img src={`${imageUrl}/${place.image}`} alt={place.name}   style={{ height: '330px', width: '350px', objectFit: 'cover' }}/>
                    <div className="tag">
                      <span className="off">Popular</span>
                    </div>
                    <div className="content-hover">
                      <Link
                        to={`/places/${place.id}`}
                        className="main-btn primary-btn"
                      >
                        places <i className="far fa-shopping-bag" />
                      </Link>
                    </div>
                  </div>
                  <div className="content">
                    <div className="info">
                      <h4 className="title">
                        <Link to={`/places/${place.id}`}>{place.name}</Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {displayedPlaces.length < places.length && (
          <button onClick={handleLoadMore} className="main-btn primary-btn">
            Load More
          </button>
        )}
      </div>
    </section>
  );
}

export default PlacesList;
