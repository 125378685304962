
import Slider from 'react-slick'

import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PlacesSkelton from './PlacesSkelton';


import Skeleton from '@mui/material/Skeleton';


// Function to conditionally load the image URL based on environment
function getPlacesImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getPlacesapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_PLACES_PRODUCTION; // Production URL
  }
}


function Places() {


  const imageUrl = getPlacesImageUrl();

  const placesApiUrl = getPlacesapiUrl();

  const [places, setPlaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadedPlaceIndexes, setLoadedPlaceIndexes] = useState(new Set());

  const handleImageLoad = (index) => {
    setLoadedPlaceIndexes((prev) => new Set(prev).add(index));
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(placesApiUrl); 
        setPlaces(response.data);
          setIsLoading(false);
     
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };
  
    fetchPlaces();
  }, []);
  

     const sliderActive5Item = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <>
        {/*====== Start Features Section ======*/}
      <section className="features-section gray-bg pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title mb-55 wow fadeInLeft">
                <span className="sub-title">Popular   
 Places at barotvalley</span>
                <h2>Most loving valley</h2>
            
              </div>
            </div>
          </div>

          {/* Display skeletons while loading */}
          {isLoading && (
          
            <PlacesSkelton/>
          )}

          {/* Display places one at a time using lazy loading */}
          {!isLoading && places.length > 0 && (
            <Slider {...sliderActive5Item} className="slider-active-5-item wow fadeInUp">
              {places.map((place, index) => (
                <Link key={place.id} to={`/places/${place.id}`}>
                  <div className="single-features-item-three">
                    <div className="img-holder">
                      <Suspense fallback={<Skeleton animation="wave" variant="circular" width={200} height={50} />}>
                       <img  src={`${imageUrl}/${place.image}`}
                          alt={place.name}
                          onLoad={() => handleImageLoad(index)} 
                        />
                        
                      </Suspense>
                    </div>
                    <div className="content">
                      <h6>{place.name}</h6>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          )}

          {/* Display error message if there's an error */}
          {error && <div>Error: {error}</div>}
        </div>
      </section>
      {/*====== End Features Section ======*/}
    </>
  )
}

export default Places
