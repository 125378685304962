import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PageBanner from '../../components/PageBanner';
import GallerySkelton from '../../components/SketonComponents/GallerySkelton';
import LazyLoad from 'react-lazyload';

import { Helmet } from 'react-helmet';

// Function to conditionally load the image URL based on environment
function getGalleryImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_GALLERY_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_GALLERY_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getGalleryapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_GALLERY_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_GALLERY_PRODUCTION; // Production URL
  }
}


function GallerPage() {

  const imageUrl = getGalleryImageUrl();

  const GalleryApiUrl = getGalleryapiUrl();
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  

  const backgroundImage =   process.env.REACT_APP_GALLERY_BG_BACKGROUND;

  const fetchGalleries = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(GalleryApiUrl);
      setGalleries(response.data);
        setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGalleries();
  }, []);

  const renderGalleries = () => {
    if (isLoading) {
      return <GallerySkelton variant="gallery" />; // Render skeleton while loading
    }

    if (error) {
      return <p>Error loading galleries: {error.message}</p>;
    }

    return (

      <>
        <Helmet>
      <title>Barot Valley Gallery: A Visual Journey</title>
      <meta name="description" content="Immerse yourself in the natural wonders of this Himalayan paradise." />
      <meta name="keywords" content="Barot Valley,Barot Valley gallery,Barot Valley photography,Barot Valley photos,Nature photography,India tourism,Himachal Pradesh,Barot Valley tourism" />
      </Helmet>

      <div className="row">
        {galleries.map((gallery, index) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
            <LazyLoad
              offset={calculateOffset(index, galleries.length)} // Dynamic offset based on index and length
              placeholder={<GallerySkelton variant="gallery-item" />} // Optional placeholder
            >
              <div className="single-gallery-item mb-30 wow fadeInUp">
                <div className="gallery-img">
                <img  src={`${imageUrl}/${gallery.image}`}
                    alt={gallery.alt_text} style={{ width: '410px', height: '400px' }}
                  />
                 
                </div>
              </div>
            </LazyLoad>
          </div>
        ))}
      </div>
      </>
    );
  };

  const calculateOffset = (index, galleryCount) => {
    // Adjust these values based on your layout and desired behavior
    const baseOffset = 700; // Initial offset when near the top
    const threshold = 0.5; // Load images when 50% within viewport
    const viewportHeight = window.innerHeight;
    const imageHeight = 300; // Adjust based on your average image height

    // Calculate approximate distance from top of the viewport
    const estimatedTopPosition = index * (imageHeight); 

    // Calculate offset based on distance from top and visibility threshold
    const offset = Math.max(
      baseOffset,
      estimatedTopPosition - (viewportHeight * threshold)
    );

    return offset;
  };

  return (
    <>
      
      {/*====== Start Gallery Section ======*/}
      <section className="gallery-area pt-100 pb-70">
       
     
        <div className="container">
        <h3 style={{
          marginBottom:'30px'
        }}>Galleries</h3>
          {renderGalleries()}</div>
      </section>
      {/*====== End Gallery Section ======*/}
    </>
  );
}

export default GallerPage;