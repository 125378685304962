
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import TestimonialSketon from "../TestimonialSketon/TestimonialSketon";

// Function to conditionally load the image URL based on environment
function getTestimonialImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_TESTIMONIALS_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_TESTIMONIALS_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getTestimonialapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_TESTIMONIALS_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_TESTIMONIALS_PRODUCTION; // Production URL
  }
}


function Testimonials() {

  const imageUrl = getTestimonialImageUrl();

  const TestimonialApiUrl = getTestimonialapiUrl();

  const [testimonials, setTestimonials] = useState([]);   

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try   
 {
        setIsLoading(true);
        const response = await axios.get(TestimonialApiUrl); // Replace with your API endpoint
          setTestimonials(response.data);
          setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchTestimonials();
  }, []);



   const sliderActive3ItemDot = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

  return (
    <>
    {/* Testimonial Section */}
    <section className="testimonial-section bg_cover pt-100 pb-100" style={{ backgroundImage: "url(assets/images/bg/map-bg.jpg)" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7">
            <div className="section-title text-center text-white mb-60">
              <span className="sub-title">Testimonials</span>
              <h2>What People Say about barot valley</h2>
            </div>
          </div>
        </div>
        {isLoading ? (
  <TestimonialSketon />
) : (
  <Slider {...sliderActive3ItemDot} className="slider-active-3-item-dot">
    {testimonials.map((testimonial) => (
      <div className="gw-testimonial-item-two" key={testimonial.id}>
        <div
          className="testimonial-inner-content"
         
        >
          <div className="quote-rating-box">
            <div className="icon">
              <img src="assets/images/testimonial/quote.png" alt="quote icon" />
            </div>
            <div className="ratings-box">
              <h4>Quality Services</h4>
              <ul className="ratings">
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
              </ul>
            </div>
          </div>
          <p  style={{
            height: '100px', // Set a fixed height for the content
            overflowY: 'auto', // Add vertical scrollbar if content overflows
            paddingRight: '10px', // Space for scrollbar to avoid overlapping
          }}>{testimonial.description}</p>
          <div className="author-thumb-title">
            <div className="author-thumb">
              <img src={`${imageUrl}/${testimonial.image}`} alt={testimonial.name} />
            </div>
            <div className="author-title">
              <h3 className="title">{testimonial.name}</h3>
              <p className="position">{testimonial.profile}</p>
            </div>
          </div>
        </div>
      </div>
    ))}
  </Slider>
)}

      </div>
    </section>
  </>
  )
}

export default Testimonials