
import PageBanner from '../../components/PageBanner'

import Slider from 'react-slick'

import { useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

// Function to conditionally load the image URL based on environment
function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION; // Production URL
  }
}

function AccommodationDetails() {

  const imageUrl = getAccommodationImageUrl();

  const AccommodationApiUrl = getAccommodationapiUrl();


  const [accommodation, setAccommodation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const backgroundImage =   process.env.REACT_APP_ACCOMMODATION_BG_BACKGROUND;


  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  const thumbs = {
    dots: false,
    arrows: false,
    speed: 800,
    autoplay: true,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const slider = {
    arrows: false,
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const badgeStyles = {
    budgetFriendly: { backgroundColor: 'green', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold',marginBottom:'30px' },
    topRated: { backgroundColor: 'gold', color: 'black', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold',marginBottom:'30px' },
    popular: { backgroundColor: 'orange', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold',marginBottom:'30px' },
    recommended: { backgroundColor: 'blue', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold',marginBottom:'30px' },
  };
  
  const getBadgeStyle = (status) => {
    switch (status?.toLowerCase()) {
      case 'top rated': return badgeStyles.topRated;
      case 'popular': return badgeStyles.popular;
      case 'recommended': return badgeStyles.recommended;
      default: return badgeStyles.budgetFriendly;
    }
  };

   
  const { accommodationId } = useParams();
  useEffect(() => {
    const fetchAccommodationDetails = async () => {

      const features = ['Family Camping', 'Wild Camping', 'Fishing', 'Mountain Biking', 'Free Wi-fi Internet', 'Transportation', 'Spa & GYM']; // Assuming you have a list of features
      
      
      try {
        setIsLoading(true);
        const response = await axios.get(`${AccommodationApiUrl}/${accommodationId}`); 
        // Replace with your API endpoint
        setAccommodation(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (accommodationId) {
      fetchAccommodationDetails(accommodationId);
    }
  }, [accommodationId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!accommodation) {
    return <p>Accommodation not found.</p>;
  }


  const sliderActive3Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : text;
  };



  const sliderActive3ItemDot = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>

<PageBanner pageTitle={"accommodations"}   backgroundImage={backgroundImage}/>
      
      <section className="shop-details-section pt-100 pb-70">
        <div className="container">
          <div className="product-details-wrapper">
            <div className="row align-items-xl-center">
              <div className="col-lg-6">
                {/*=== Product Gallery ===*/}
                <div className="product-gallery-area mb-50 wow fadeInLeft">
                <Slider
                  {...slider}
                  
                  ref={(slider) => setSlider1(slider)}
                  className="product-big-slider mb-30"
                >
                   {accommodation.image.map((image, index) => (
                  <div className="product-img">
                    <a href="assets/images/shop/product-big-1.jpg" className="img-popup">
                      <img  src={`${imageUrl}/${image}`} alt="Product"  style={{
                        width:'630px',
                        height:'540px'
                      }}/>
                    </a>
                  </div>
                   ))}
                
                </Slider>
              <Slider
                {...thumbs}
                asNavFor={nav1}
                ref={(slider) => setSlider2(slider)}
                className="product-thumb-slider"
              >
                  {accommodation.image.map((image, index) => (
              <div className="product-img">
                <img  src={`${imageUrl}/${image}`} alt="Product"  style={{
                        width:'190px',
                        height:'170px'
                      }}/>
              </div>
               ))}
               
       
      </Slider>
    </div>
              </div>
              <div className="col-lg-6">
                
                <div className="product-info mb-50 pl-lg-70 wow fadeInRight">
                <div className="tag">
                  <span
                  className="off"
                  style={getBadgeStyle(accommodation.badge_status)}
                  >
                  {accommodation.badge_status || 'Budget Friendly'}
                  </span>
                 
                  </div>
               
                  <h4 className="title">{accommodation.name}</h4>
                  
                  

                  <p dangerouslySetInnerHTML={{ __html: truncateText(accommodation.description,30) }} />
                  
                  <div className="product-cart mt-20 mb-30">
                    <ul>
                      
                      <li>
                      <button
  className="main-btn primary-btn"
  onClick={() => {
    if (!accommodation || !accommodation.name) {
      console.error("Accommodation name is not available.");
      return;
    }
    const accommodationName = accommodation.name; // Get the selected accommodation name
    const message = `Hello, I am interested in this accommodation ${encodeURIComponent(accommodationName)}!`; // Construct the message
    const whatsappURL = `https://wa.me/7018079512?text=${message}`; // Build the WhatsApp URL
    window.open(whatsappURL, "_blank"); // Open the URL in a new tab
  }}
>
BookNow
                    <i className="far fa-paper-plane" />
                  </button>




                      </li>
                    </ul>
                  </div>
                
                  <ul className="social-link">
                    <li>
                      <span>Share</span>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>

      <section className="destination-details-section">
        <div className="container">
          <div className="destination-details-wrapper">
            <div className="destination-info wow fadeInUp">
             
            
              <h3>Why Choose {accommodation.name}</h3>
            
              
               <p dangerouslySetInnerHTML={{ __html: accommodation.description }} />
              
              <ul className="features-list mb-40">
              {accommodation.amenities.map((amenities, index) => (
                <li>
                  <span>
                    <i className="fas fa-badge-check" />
                    {amenities}
                  </span>
                </li>
              ))}
              </ul>
            </div>
           
          </div>
        </div>
      </section>
   
    
    </>
  )
}

export default AccommodationDetails