import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import PageBanner from '../../components/PageBanner';
import AccommodationSkelton from './AccommodationSkelton';

function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION;
  }
}

function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION;
  }
}

function AccommodationPage() {
  const backgroundImage = process.env.REACT_APP_ACCOMMODATION_BG_BACKGROUND;
  const imageUrl = getAccommodationImageUrl();
  const AccommodationApiUrl = getAccommodationapiUrl();

  const [accommodations, setAccommodations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayedAccommodations, setDisplayedAccommodations] = useState([]);
  const [filter, setFilter] = useState('default');

  const badgeStyles = {
    budgetFriendly: { backgroundColor: 'green', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold' },
    topRated: { backgroundColor: 'gold', color: 'black', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold' },
    popular: { backgroundColor: 'orange', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold' },
    recommended: { backgroundColor: 'blue', color: 'white', padding: '5px 10px', borderRadius: '5px', fontSize: '14px', fontWeight: 'bold' },
  };

  const getBadgeStyle = (status) => {
    switch (status?.toLowerCase()) {
      case 'top rated': return badgeStyles.topRated;
      case 'popular': return badgeStyles.popular;
      case 'recommended': return badgeStyles.recommended;
      default: return badgeStyles.budgetFriendly;
    }
  };

  useEffect(() => {
    const fetchAccommodations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(AccommodationApiUrl);
        setAccommodations(response.data);
        setDisplayedAccommodations(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchAccommodations();
  }, [AccommodationApiUrl]);

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === 'default') {
      setDisplayedAccommodations(accommodations);
    } else {
      const filteredData = accommodations.filter(
        (accommodation) => accommodation.badge_status?.toLowerCase() === selectedFilter.toLowerCase()
      );
      setDisplayedAccommodations(filteredData);
    }
  };

  return (
    <>
      <Helmet>
        <title>Discover Barot Valley: A Serene Himalayan Retreat</title>
        <meta name="description" content="Discover comfortable and affordable accommodations in Barot Valley, a serene Himalayan retreat. Explore a range of options, from cozy cottages to luxurious retreats. Enjoy the breathtaking beauty of the Himalayas while staying in our comfortable and well-equipped accommodations." />
        <meta name="keywords" content="Barot Valley, accommodation, hotels, resorts, cottages, Himalayan retreat, nature, adventure, travel,best hotel in barot valley" />
      </Helmet>

      <section className="products-section pt-100 pb-90">
        <div className="container">
          <div className="page-item-filter mb-40 wow fadeInUp">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <div className="show-text mb-20">
                  <h3>Accommodations</h3>
                </div>
              </div>
              <div className="col-md-5">
                <div className="product-dropdown float-md-end">
                  <span className="title">Sort By</span>
                  <select className="wide" value={filter} onChange={handleFilterChange}>
                    <option value="default">Default</option>
                    <option value="top rated">Top Rated</option>
                    <option value="popular">Popular</option>
                    <option value="recommended">Recommended</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {isLoading ? (
              <AccommodationSkelton />
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              displayedAccommodations.map((accommodation, index) => (
                <div className="col-xl-4 col-md-6 col-sm-12" key={index}>
                  <div className="single-product-item mb-50 wow fadeInUp">
                    <div className="img-holder">
                      <img
                        src={`${imageUrl}/${accommodation.image[0]}`}
                        alt={accommodation.name}
                        style={{ height: '330px', width: '350px', objectFit: 'cover' }}
                      />
                      <div className="tag">
                        <span
                          className="off"
                          style={getBadgeStyle(accommodation.badge_status)}
                        >
                          {accommodation.badge_status || 'Budget Friendly'}
                        </span>
                      </div>
                      <div className="content-hover">
                        <Link to={`/accommodation/${accommodation.id}`} className="main-btn primary-btn">
                          Read More..
                        </Link>
                      </div>
                    </div>
                    <div className="content">
                      <div className="info">
                        <h5 className="title">
                          <Link to={`/accommodation/${accommodation.id}`}>{accommodation.name}</Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default AccommodationPage;
