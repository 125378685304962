

import Slider from "react-slick";
import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';

import { Link } from "react-router-dom";
import HomeSliderSketon from "../SketonComponents/HomeSliderSketon";

// Function to conditionally load the image URL based on environment
function getSliderImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_SLIDER__IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_SLIDER_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getSliderapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_SLIDER_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_SLIDER_PRODUCTION; // Production URL
  }
}



function HomeSlider() {

  const imageUrl = getSliderImageUrl();

  const SliderapiUrl = getSliderapiUrl();

  const [sliderData, setSliderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(SliderapiUrl);
        setSliderData(response.data);
      } catch (error) {
        console.error('Error fetching slider data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSliderData();
  }, []);



    function Arrow({ className, extraClass, onClick, icon }) {
        return (
          <div className={`${className}  ${extraClass}`} onClick={onClick}>
            <i className={icon}></i>
          </div>
        );
      }
  
       const home1Slider = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        fade: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <Arrow extraClass={"prev"} icon={"fal fa-arrow-left"} />,
        nextArrow: <Arrow extraClass={"next"} icon={"fal fa-arrow-right"} />,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
            },
          },
        ],
      };

  return (
    <>
      <section className="hero-section">
        {/*=== Hero Wrapper ===*/}
        <div className="hero-wrapper black-bg">
        
      {isLoading ? (
        <HomeSliderSketon/>
        
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <Slider {...home1Slider} className="hero-slider-one">
          {sliderData.map((slide, index) => (
            <div className="single-slider" key={index}>
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-xl-6">
                    <div className="hero-content text-white">
                      <h2>{slide.heading}</h2>
                      <p dangerouslySetInnerHTML={{ __html: slide.description }} />
                      <Link to="/places" className="main-btn primary-btn">
                              Explore More
                              <i className="fas fa-paper-plane" />
                            </Link>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="hero-image">
                    <img src={`${imageUrl}/${slide.image}`}  alt={slide.image} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
   
        </div>
      </section>
      
    </>
  )
}

export default HomeSlider