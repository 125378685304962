
import PageBanner from '../../components/PageBanner'

import Slider from 'react-slick'

import { useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

// Function to conditionally load the image URL based on environment
function getPlacesImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_PLACES_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_PLACES_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getPlacesapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_PLACES_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_PLACES_PRODUCTION; // Production URL
  }
}


function PlacesDetails() {

  const imageUrl = getPlacesImageUrl();

  const placesApiUrl = getPlacesapiUrl();

  const [places, setPlaces] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const backgroundImage =   process.env.REACT_APP_PLACES_BG_PRODUCTION;
   
  const { placesId } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  const thumbs = {
    dots: false,
    arrows: false,
    speed: 800,
    autoplay: true,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const slider = {
    arrows: false,
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : text;
  };


  useEffect(() => {
    const fetchPlacesDetails = async () => {

      const features = ['Family Camping', 'Wild Camping', 'Fishing', 'Mountain Biking', 'Free Wi-fi Internet', 'Transportation', 'Spa & GYM']; // Assuming you have a list of features

      
      try {
        setIsLoading(true);
        const response = await axios.get(`${placesApiUrl}/${placesId}`); 
        // Replace with your API endpoint
        setPlaces(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (placesId) {
        fetchPlacesDetails(placesId);
    }
  }, [placesId]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!places) {
    return <p>Places not found.</p>;
  }


  const sliderActive3Item = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderActive3ItemDot = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>

<PageBanner pageTitle={"place details"}  backgroundImage={backgroundImage}/>
      {/*====== Start Destination Details Section ======*/}
    
    
      <section className="shop-details-section pt-100 pb-70">
        <div className="container">
          <div className="product-details-wrapper">
            <div className="row align-items-xl-center">
              <div className="col-lg-6">
                {/*=== Product Gallery ===*/}
                <div className="product-gallery-area mb-50 wow fadeInLeft">
                <Slider
                  {...slider}
                  
                  ref={(slider) => setSlider1(slider)}
                  className="product-big-slider mb-30"
                >
                  
                  <div className="product-img">
                    <a href="assets/images/shop/product-big-1.jpg" className="img-popup">
                      <img  src={`${imageUrl}/${places.image}`} alt="Product"  style={{
                        width:'630px',
                        height:'540px'
                      }}/>
                    </a>
                  </div>
              
                
                </Slider>
            
    </div>
              </div>
              <div className="col-lg-6">
                <div className="product-info mb-50 pl-lg-70 wow fadeInRight">
                  <h4 className="title">{places.name}</h4>
                  
              

                  <p dangerouslySetInnerHTML={{ __html: truncateText(places.description,30) }} />
                 
                 
                  <div className="product-cart mt-20 mb-30">
                    <ul>
                      
                      <li>
                      <button
  className="main-btn primary-btn"
  onClick={() => {
    if (!places || !places.name) {
      console.error("Accommodation name is not available.");
      return;
    }
    const accommodationName = places.name; // Get the selected accommodation name
    const message = `Hello, I am interested in this place ${encodeURIComponent(accommodationName)} can i know more about this!`; // Construct the message
    const whatsappURL = `https://wa.me/7018079512?text=${message}`; // Build the WhatsApp URL
    window.open(whatsappURL, "_blank"); // Open the URL in a new tab
  }}
>
BookNow
                    <i className="far fa-paper-plane" />
                  </button>

                      </li>
                    </ul>
                  </div>
                 
                
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>

      <section className="destination-details-section">
        <div className="container">
          <div className="destination-details-wrapper">
            <div className="destination-info wow fadeInUp">
             
            
              <h3>Why Choose {places.name}</h3>
            
              
              <p dangerouslySetInnerHTML={{ __html: places.description }} />
              
            
            </div>
           
          </div>
        </div>
      </section>
   
      
   
    </>
  )
}

export default PlacesDetails