
import Slider from 'react-slick';
import AccommodationSkelton from '../AccommodationSketon/AccommodationSkelton';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

// Function to conditionally load the image URL based on environment
function getAccommodationImageUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_ACCOMMODATION_IMAGE_URL_PRODUCTION; // Production URL
  }
}

// Function to conditionally load the image URL based on environment
function getAccommodationapiUrl() {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_DEVELOPMENT; // Local development URL
  } else {
    return process.env.REACT_APP_API_URL_ACCOMMODATION_PRODUCTION; // Production URL
  }
}



function Accommodation() {

  const imageUrl = getAccommodationImageUrl();

  const AccommodationApiUrl = getAccommodationapiUrl();

  const [accommodations, setAccommodations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchAccommodations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(AccommodationApiUrl); // Replace with your API endpoint
          setAccommodations(response.data);
          setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchAccommodations();
  }, []);

     const sliderActive4Item = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };

      
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit ? words.slice(0, wordLimit).join(" ") + "..." : text;
  };


  return (
    <>{/*====== Start About Section ======*/}
    <section className="about-section pt-100">
  <div className="container-fluid">
    <div className="row justify-content-center">
      <div className="col-xl-6 col-lg-9">
        {/*=== About Content Box ===*/}
        <div className="about-content-box text-center mb-55 wow fadeInDown">
          <div className="section-title mb-30">
            <span className="sub-title">Accommodations</span>
            <h2>Discover comfortable and affordable accommodations in Barot Valley</h2>
          </div>
          
        </div>
      </div>
    </div>
    <div className="slider-container">
  {isLoading ? (
    <AccommodationSkelton />
  ) : (
    <Slider {...sliderActive4Item} className="slider-active-4-item wow fadeInUp">
      {accommodations.map((accommodation) => (
        
          <div className="single-service-item mb-40" key={accommodation.id}>
        <div className="content">
          <h5 className="title" style={{
            marginBottom:'10px'
          }}><Link key={accommodation.id} to={`/accommodation/${accommodation.id}`}>{accommodation.name}</Link></h5>
        
          <div className="meta">
            <span className="icon"><i className="flaticon-blanket" /></span>
            <span className="icon"><i className="flaticon-caravan" /></span>
            <span className="icon"><i className="flaticon-travel" /></span>
            <span className="icon"><i className="flaticon-fire" /></span>
          </div>
         <p dangerouslySetInnerHTML={{ __html: truncateText(accommodation.description,20) }} />
          
          <Link  key={accommodation.id} to={`/accommodation/${accommodation.id}`} class="main-btn filled-btn" tabindex="0">Check Now<i class="fas fa-paper-plane"></i></Link>
        </div>
        <div className="img-holder">
          <img src={`${imageUrl}/${accommodation.image[0]}`} alt={accommodation.name} style={{
    width: '370px',
    height: '250px',
  }} />
        </div>
        
      </div>

      ))}
    </Slider>
  )}
</div>
  </div>
</section>

    </>
  )
}

export default Accommodation